import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  setAgeGroup,
  load,
  setCurrentBook,
  stopLoad,
} from "../../../../reducers/adminSlice";
import axios, { Axios } from "axios";
import "./styles.scss";
import { AiOutlineClose, AiOutlineUpload } from "react-icons/ai";
import { setAlert } from "../../../../reducers/authSlice";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import LeftRotate from "../../../icons/LeftRotate";
import RightRotate from "../../../icons/RightRotate";

const Book = () => {
  const {
    admin: { categories, currentBook, sections },
    api: { urls },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { isbn } = useParams();
  const [newCategory, setNewCategory] = useState(0);
  const [newSection, setNewSection] = useState(0);
  const [initialBook, setInitialBook] = useState({});
  const [videoSource, setVideoSource] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [nextToggle, setNextToggle] = useState(true);
  const [secondaryImages, setSecondaryImages] = useState(null);
  const [secondaryUploadedImages, setSecondaryUploadedImages] = useState([{}]);
  const [imageRotations, setImageRotations] = useState({});

  const [bookMiscellaneousDetails, setBookMiscellaneousDetails] = useState({
    language: null,//
    description: null,//
    lexileMeasure:null,//
    publisher: null,//
    publicationDate: null,//
    pages: null,//
    genre: null,//
    author:null,//
    paperbackprice:null,//
    boardbookprice:null,//
    hardcoverprice:null,//
  });

  const [genreList, setGenreList] = useState(null);

  const navigate = useNavigate();

  const handleSecondaryImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setSecondaryUploadedImages([...secondaryUploadedImages, selectedImage]);
    }
  };

  useEffect(() => {
    const getSecondaryImages = async () => {
      const response = await axios.get(
        `https://server.brightr.club/api_v2_books/book-images/${isbn}`
      );

      setSecondaryImages(response.data.other_images);

      const rotationData = {};
      response.data.other_images.forEach((image) => {
        rotationData[image.image_id] = image.image_angle || 0; // Set angle or default to 0
      });
      setImageRotations(rotationData);
    };
    getSecondaryImages();
  }, [isbn]);

  const getBook = async () => {
    try {
      const response = await axios.get(urls.getBooks, {
        params: {
          start: 0,
          end: 1,
          search_query: String(isbn),
        },
      });
      dispatch(setCurrentBook({ currentBook: response.data.books[0] }));
      setInitialBook(response.data.books[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const updateCurrentBook = (event) => {
    console.log(event.target.value)
    dispatch(
      setCurrentBook({
        currentBook: {
          ...currentBook,
          [event.target.name]: event.target.value,
        },
      })
    );
    setBookMiscellaneousDetails((prevState) => ({
      ...prevState,
      [event.target.name]:event.target.value
    }));
  };

  const updateCurrentBookImage = (event) => {
    dispatch(
      setCurrentBook({
        currentBook: { ...currentBook, image: event.target.files[0] },
      })
    );
  };

  const updateCurrentBookCategory = (event, items, i) => {
    const _currentBook = {
      ...currentBook,
      categories: currentBook.categories.map((category) => ({ ...category })),
    };
    _currentBook.categories[i][event.target.name] = items.find(
      (item) => item.id === Number(event.target.value)
    );
    dispatch(setCurrentBook({ currentBook: _currentBook }));
  };

  const updateCurrentBookGenre = (event, items, i) => {
    const selectedOption = event.target.value;

    setBookMiscellaneousDetails((prevState) => ({
      ...prevState,
      genre: selectedOption,
    }));
    console.log(bookMiscellaneousDetails);
  };

  const addCurrentBookCategory = () => {
    const _currentBook = {
      ...currentBook,
      categories: [...currentBook.categories],
    };
    _currentBook.categories.push({
      category: categories[newCategory],
      section: sections[newSection],
    });
    dispatch(setCurrentBook({ currentBook: _currentBook }));
    setNewCategory(0);
    setNewSection(0);
  };

  const removeCurrentBookCategory = (categoryName, sectionName) => {
    const _currentBook = {
      ...currentBook,
      categories: [...currentBook.categories],
    };
    _currentBook.categories = _currentBook.categories.filter(
      (category) =>
        category.category.name !== categoryName ||
        category.section.name !== sectionName
    );
    dispatch(setCurrentBook({ currentBook: _currentBook }));
  };

  const addOrUpdateBook = async () => {
    dispatch(load());
    try {
      const formData = new FormData();
      formData.append("id", currentBook.id);
      formData.append("isbn", currentBook.isbn);
      formData.append("name", currentBook.name);
      formData.append("image", currentBook.image);
      formData.append("rating", currentBook.rating);
      formData.append("review_count", currentBook.review_count);
      formData.append("min_age", currentBook.min_age);
      formData.append("max_age", currentBook.max_age);
      formData.append("categories", JSON.stringify(currentBook.categories));
      formData.append("genre", bookMiscellaneousDetails.genre);
      formData.append("pages",bookMiscellaneousDetails.pages);
      formData.append("lexile_measure",bookMiscellaneousDetails.lexileMeasure)
      formData.append("description",bookMiscellaneousDetails.description)
      formData.append("publication_date",bookMiscellaneousDetails.publicationDate)
      formData.append("publisher",bookMiscellaneousDetails.publisher)
      formData.append("author",bookMiscellaneousDetails.author)
      formData.append("language",bookMiscellaneousDetails.language)
      formData.append("paperbackprice",bookMiscellaneousDetails.paperbackprice)
      formData.append("boardbookprice",bookMiscellaneousDetails.boardbookprice)
      formData.append("hardcoverprice",bookMiscellaneousDetails.hardcoverprice)
      let response;
      if (currentBook.isAdding) {
        response = await axios.post(urls.addOrUpdateBook, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        });
      } else if (currentBook.isEditing) {
        response = await axios.put(urls.addOrUpdateBook, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        });
      }
      dispatch(
        setCurrentBook({
          currentBook: {
            ...response.data.book,
            isEditing: false,
            isAdding: false,
          },
        })
      );
    } catch (err) {
      dispatch(setAlert({ text: err.response.data.message, color: "red" }));
    }
    dispatch(stopLoad());
  };

  const cancelAddOrUpdateBook = () => {
    if (currentBook.isAdding) navigate("/");
    if (currentBook.isEditing)
      dispatch(setCurrentBook({ currentBook: initialBook }));
  };

  useEffect(() => {
    dispatch(setAgeGroup({ ageGroup: -1 }));
    if (!currentBook.isbn && isbn !== "add") getBook();
    else setInitialBook(currentBook);
  }, []);

  useEffect(() => {
    const fetchVideoSource = async () => {
      if (isbn) {
        try {
          const response = await axios.get(
            `https://server.brightr.club/api_v2_books/book-video/${isbn}`
          );
          setVideoSource(response.data.video_source);
          console.info(response.data.video_source);
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchVideoSource();
  }, [videoSource]);

  useEffect(() => {
    console.log(secondaryUploadedImages);
  }, [secondaryUploadedImages]);
  useEffect(() => {}, [isbn]);

  const handleUpload = async () => {
    if (!selectedFile) {
      console.error("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("isbn", isbn);
    formData.append("video", selectedFile);

    try {
      const response = await axios.post(
        "https://server.brightr.club/api_v2_books/new-book-video",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        alert("Video uploaded successfully!");
        setVideoSource();
      } else {
        alert("Cant Upload Video");
        console.error("Video upload failed:", response.data.message);
      }
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadSecondaryImage = async (isbn) => {
    try {
      const formData = new FormData();
      secondaryUploadedImages.slice(1).forEach((image) => {
        formData.append("images", image);
      });
      const response = await axios.post(
        `https://server.brightr.club/api_v2_books/add-book-images/${isbn}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.data.success) {
        alert("Images uploaded successfully");
      } else {
        alert("Failed to upload images");
      }
    } catch (error) {
      alert("Error uploading images", error);
      console.error("Error:", error);
    }
  };

  const handleDeleteImage = async (ImageId) => {
    try {
      const response = await axios.get(
        `https://server.brightr.club/api_v2_books/delete-book-image/${ImageId}`
      );
      console.log(response);
      const updatedSecondaryImages = secondaryImages.filter(
        (image) => image.image_id !== ImageId
      );
      // updatedSecondaryImages will contain the modified array without the specified image_id
      console.log(updatedSecondaryImages);
      // Set the updated array back to state if needed
      setSecondaryImages(updatedSecondaryImages);
    } catch (error) {
      console.error("Error while deleting image:", error);
      // Handle errors here
    }
  };

  const handleRotateRight = async (imageId) => {
    try {
      const currentAngle = imageRotations[imageId] || 0;
      const newAngle = currentAngle + 90;
      const response = await axios.get(
        `https://server.brightr.club/api_v2_books/change-book-angle/${imageId}?new_angle=${newAngle}`
      );

      if (response.data.success) {
        setImageRotations({
          ...imageRotations,
          [imageId]: newAngle, // Set the new angle in the state
        });
      } else {
        alert("Failed to change image angle"); // Optional: Display error message
      }
    } catch (error) {
      console.error("Error changing image angle:", error);
    }
  };

  const handleRotateLeft = async (imageId) => {
    try {
      const currentAngle = imageRotations[imageId] || 0;
      const newAngle = currentAngle - 90;
      const response = await axios.get(
        `https://server.brightr.club/api_v2_books/change-book-angle/${imageId}?new_angle=${newAngle}`
      );

      if (response.data.success) {
        setImageRotations({
          ...imageRotations,
          [imageId]: newAngle, // Set the new angle in the state
        });
      } else {
        alert("Failed to change image angle"); // Optional: Display error message
      }
    } catch (error) {
      console.error("Error changing image angle:", error);
    }
  };
  const FetchDetails = async () => {
    try {
       const response = await axios.get(
        `https://server.brightr.club/api_v2_books/getBookDetails?isbn=${isbn}`
      );

      const bookDetails = response.data.book_details;
      setBookMiscellaneousDetails((prevState) => ({
        ...prevState,
        genre: bookDetails.genre,
        language: bookDetails.language,
        description: bookDetails.description,
        lexileMeasure: bookDetails.lexile_measure,
        publisher: bookDetails.publisher,
        publicationDate: bookDetails.publication_date,
        pages: bookDetails.pages,
        author:bookDetails.author,
        paperbackprice:bookDetails.paperbackprice,
        boardbookprice:bookDetails.boardbookprice,
        hardcoverprice:bookDetails.hardcoverprice,
      }));
    } catch (error) {
      console.error(error);
    }
  };
  const FetchGenresList = async () => {
    try {
      const response = await axios.get(
        `https://server.brightr.club/api_v2_books/get-genres`
      );
      setGenreList(response.data.genres);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    FetchDetails();
    FetchGenresList();
  }, [isbn]);

  if (!currentBook.isbn && isbn !== "add") return <p>Loading...</p>;
  return (
    <div className="current-book">
      <div className="book-image">
        {typeof currentBook.image === typeof "" &&
          currentBook.image.startsWith("http") && (
            <>
              {!(currentBook.isAdding || currentBook.isEditing) && (
                <Splide style={{ maxWidth: "350px" }}>
                  <SplideSlide>
                    <img
                      className="book-image"
                      src={currentBook.image.replace("218", "436")}
                      alt="Book"
                    />
                  </SplideSlide>
                  {secondaryImages &&
                    secondaryImages.map((image, i) => {
                      if (i === 0) return <Fragment key={i} />;
                      return (
                        <SplideSlide key={image}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              style={{
                                maxHeight: "300px",
                                maxWidth: "200px",
                                objectFit: "cover",
                                transform: `rotate(${
                                  imageRotations[image.image_id] || 0
                                }deg)`,
                              }}
                              className="book-image"
                              src={image.image_source}
                              alt="Book"
                            />

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                              }}
                            >
                              <button
                                onClick={() => handleRotateLeft(image.image_id)}
                              >
                                <LeftRotate />
                              </button>
                              <button
                                style={{
                                  fontSize: "16px",
                                  backgroundColor: "#ff0000",
                                  color: "#fff",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                                onClick={() =>
                                  handleDeleteImage(image.image_id)
                                }
                              >
                                Delete
                              </button>
                              <button
                                onClick={() =>
                                  handleRotateRight(image.image_id)
                                }
                              >
                                <RightRotate />
                              </button>
                            </div>
                          </div>
                        </SplideSlide>
                      );
                    })}

                  <SplideSlide
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {videoSource && (
                      <div className="image-container">
                        <div key={videoSource} className="book-video">
                          <video
                            key={videoSource}
                            className="video"
                            src={videoSource}
                            autoPlay
                            muted
                            loop
                          ></video>
                        </div>
                      </div>
                    )}
                  </SplideSlide>
                </Splide>
              )}
            </>
          )}
        {(currentBook.isAdding || currentBook.isEditing) && (
          <>
            <div className="upload-book-image">
              <Splide options={{ type: "loop" }} style={{ maxWidth: "300px" }}>
                <SplideSlide
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <input
                    type="file"
                    onChange={updateCurrentBookImage}
                    accept="image/*"
                  />
                  <AiOutlineUpload />
                  <h2 style={{ textAlign: "center" }}>
                    {" "}
                    Upload Main Image Here
                  </h2>
                  {currentBook.image &&
                    typeof currentBook.image !== typeof "" && (
                      <p>{currentBook.image.name} uploaded</p>
                    )}
                </SplideSlide>

                {secondaryUploadedImages.slice(0).map((image, index) => (
                  <SplideSlide
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AiOutlineUpload /> upload secondary image {index + 2}
                    <input
                      type="file"
                      onChange={handleSecondaryImageUpload}
                      accept="image/*"
                    />
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </>
        )}
      </div>

      <div className="book-details">
        <div className="book-detail">
          <span>ISBN</span>
          {currentBook.isAdding || currentBook.isEditing ? (
            <input
              type="number"
              value={currentBook.isbn}
              name="isbn"
              onChange={updateCurrentBook}
            />
          ) : (
            <p>{currentBook.isbn}</p>
          )}
        </div>
        <div className="book-detail">
          <span>Name</span>
          {currentBook.isAdding || currentBook.isEditing ? (
            <textarea
              value={currentBook.name}
              name="name"
              onChange={updateCurrentBook}
            />
          ) : (
            <p>{currentBook.name}</p>
          )}
        </div>
        {(currentBook.isAdding || currentBook.isEditing) && (
          <div>
            <div className="file-upload">
              <label htmlFor="fileInput" className="file-label">
                Choose VIdeo File
              </label>
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                className="file-input"
              />
            </div>

            <button
              className="upload-video-button"
              type="button"
              onClick={handleUpload}
            >
              Upload VIdeo
            </button>

            {secondaryUploadedImages.length >= 1 && (
              <button
                type="button"
                style={{ marginTop: "10px" }}
                className="upload-video-button"
                onClick={() => handleUploadSecondaryImage(isbn)}
              >
                Upload Secondary Images
              </button>
            )}
          </div>
        )}
        <div className="book-detail-list">
          <div className="book-detail">
            <span>Minimum Age</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="number"
                value={currentBook.min_age}
                name="min_age"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{currentBook.min_age}</p>
            )}
          </div>

          <div className="book-detail">
            <span>Maximum Age</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="number"
                value={currentBook.max_age}
                name="max_age"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{currentBook.max_age}</p>
            )}
          </div>
        </div>

        <div className="book-detail-list">
          <div className="book-detail">
            <span>Rating</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="text"
                value={currentBook.rating}
                name="rating"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{currentBook.rating}</p>
            )}
          </div>
          <div className="book-detail">
            <span>Review Count</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="number"
                value={currentBook.review_count}
                name="review_count"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{currentBook.review_count}</p>
            )}
          </div>
        </div>
        
          <div className="book-detail">
            <span>Description</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <textarea
                type="text"
                style={{height:"200px"}}
                value={bookMiscellaneousDetails.description}
                name="description"
                onChange={updateCurrentBook}
              />
             
              
            ) : (
              <p>{bookMiscellaneousDetails.description||"NA"}</p>
            )}
          </div>
          <div className="book-detail">
            <span>Authors</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <textarea
                type="text"
                style={{height:"200px"}}
                value={bookMiscellaneousDetails.author}
                name="author"
                onChange={updateCurrentBook}
              />
             
              
            ) : (
              <p>{bookMiscellaneousDetails.author||"NA"}</p>
            )}
          </div>
          
        
        <div className="book-detail-list">
          <div className="book-detail">
            <span>Pages</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="number"
                value={bookMiscellaneousDetails.pages}
                name="pages"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{bookMiscellaneousDetails.pages || "NA"}</p>
            )}
          </div>
          <div className="book-detail">
            <span>Lexile Measure</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="text"
                value={bookMiscellaneousDetails.lexileMeasure}
                name="lexileMeasure"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{bookMiscellaneousDetails.lexileMeasure || "NA"}</p>
            )}
          </div>
        </div>

        
        <div className="book-detail-list">
          <div className="book-detail">
            <span>Publication Date</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="date"
                value={bookMiscellaneousDetails.publicationDate}
                name="publicationDate"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{bookMiscellaneousDetails.publicationDate || "NA"}</p>
            )}
          </div>
          <div className="book-detail">
            <span>Publisher</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="text"
                value={bookMiscellaneousDetails.publisher}
                name="publisher"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{bookMiscellaneousDetails.publisher || "NA"}</p>
            )}
          </div>
          <div className="book-detail">
            <span>language</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="text"
                value={bookMiscellaneousDetails.language}
                name="language"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{bookMiscellaneousDetails.language || "NA"}</p>
            )}
          </div>
          
          
        </div>

        <div className="book-detail-list">
          <div className="book-detail">
            <span>Paper Back Price</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="number"
                value={bookMiscellaneousDetails.paperbackprice}
                name="paperbackprice"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{bookMiscellaneousDetails.paperbackprice || "NA"}</p>
            )}
          </div>
          <div className="book-detail">
            <span>Board Book Price</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="number"
                value={bookMiscellaneousDetails.boardbookprice}
                name="boardbookprice"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{bookMiscellaneousDetails.boardbookprice || "NA"}</p>
            )}
          </div>
          <div className="book-detail">
            <span>Hard Cover Price</span>
            {currentBook.isAdding || currentBook.isEditing ? (
              <input
                type="number"
                value={bookMiscellaneousDetails.hardcoverprice}
                name="hardcoverprice"
                onChange={updateCurrentBook}
              />
            ) : (
              <p>{bookMiscellaneousDetails.hardcoverprice || "NA"}</p>
            )}
          </div>
          
          
        </div>
        
        <div className="book-detail">
          <span>Categories</span>
          <div className="book-category-list">
            {currentBook.categories?.map((category, i) => {
              if (currentBook.isAdding || currentBook.isEditing) {
                return (
                  <Fragment>
                    <div className="book-category-input">
                      <select
                        value={category.section.id}
                        name="section"
                        onChange={(event) =>
                          updateCurrentBookCategory(event, sections, i)
                        }
                      >
                        {sections.map((inputSection) => {
                          return (
                            <option value={inputSection.id}>
                              {inputSection.name}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        value={category.category.id}
                        name="category"
                        onChange={(event) =>
                          updateCurrentBookCategory(event, categories, i)
                        }
                      >
                        {categories.map((inputCategory) => {
                          return (
                            <option value={inputCategory.id}>
                              {inputCategory.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <button
                      className="remove-category-button"
                      onClick={() =>
                        removeCurrentBookCategory(
                          category.category.name,
                          category.section.name
                        )
                      }
                    >
                      <AiOutlineClose />
                    </button>
                  </Fragment>
                );
              }
              return (
                <p className="book-category">
                  {category.section.name} | {category.category.name}
                </p>
              );
            })}
          </div>
          <span>Genres</span>
          <div className="book-category-list">
            {genreList && (currentBook.isAdding || currentBook.isEditing) ? (
              <Fragment>
                <div className="book-category-input">
                  <select
                    name="section"
                    onChange={(event) => updateCurrentBookGenre(event)}
                  >
                   { bookMiscellaneousDetails.genre ? <option>{bookMiscellaneousDetails.genre}</option>:<option>
                    {null}
                    </option>}
                    {genreList.map((inputSection) => (
                      <option key={inputSection.id} value={inputSection.name}>
                        {inputSection.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Fragment>
            ) : (
              <p className="book-category">
                {"genre"} | {bookMiscellaneousDetails.genre || "NA"}
              </p>
            )}
            
          </div>
          

          {(currentBook.isAdding || currentBook.isEditing) && (
            <Fragment>
              <span>Add New Category</span>
              <div className="book-category-input">
                <select
                  value={newSection}
                  onChange={(e) => setNewSection(e.target.value)}
                >
                  {sections.map((inputSection, i) => {
                    return <option value={i}>{inputSection.name}</option>;
                  })}
                </select>
                <select
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                >
                  {categories.map((inputCategory, i) => {
                    return <option value={i}>{inputCategory.name}</option>;
                  })}
                </select>
              </div>
              <button
                className="add-category-button"
                onClick={addCurrentBookCategory}
              >
                +
              </button>
            </Fragment>
          )}
        </div>
        {!currentBook.isAdding && !currentBook.isEditing && (
          <input
            type="button"
            value="Edit"
            className="gold-button"
            onClick={() =>
              dispatch(
                setCurrentBook({
                  currentBook: { ...currentBook, isEditing: true },
                })
              )
            }
          />
        )}
        {(currentBook.isAdding || currentBook.isEditing) && (
          <div className="add-update-actions">
            <input
              type="button"
              value="Done"
              className="gold-button"
              style={{ backgroundColor: "#33A200" }}
              onClick={addOrUpdateBook}
            />
            <input
              type="button"
              value="Cancel"
              className="gold-button"
              style={{ backgroundColor: "red" }}
              onClick={cancelAddOrUpdateBook}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Book;
