export const ageGroups = [
  { id: 1, color: "#F75549", age: "0-2", name: "age_group_1", title: "0-2" },
  { id: 2, color: "#FF8513", age: "3-5", name: "age_group_2", title: "3-5" },
  { id: 3, color: "#F8B200", age: "6-8", name: "age_group_3", title: "6-8" },
  {
    id: 4,
    color: "#33A200",
    age: "9-11",
    name: "age_group_4",
    title: "9-11",
  },
  {
    id: 5,
    color: "#1596DC",
    age: "12-14",
    name: "age_group_5",
    title: "12-14",
  },
  { id: 6, color: "#CB85C2", age: "15+", name: "age_group_6", title: "15+" },
];

export const bookFilters = [
  { id: 1, title: "Authors", key: "authors" },
  { id: 2, title: "Publishers", key: "publishers" },
  { id: 3, title: "Series", key: "series" },
  { id: 4, title: "Types", key: "types" },
];

export const bookColumns = {
  required: ["isbn", "name"],
  optional: [
    "image",
    "rating",
    "review_count",
    "min_age",
    "max_age",
    "language",
    "price",
    "grade_level",
    "pages",
    "dimensions",
    "for_age",
    "publisher",
    "lexile_measure",
    "stock_available",
    "rentals",
    "categories",
  ],
  multiple: ["image"],
};
