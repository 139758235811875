import {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import { setAgeGroup } from '../../../reducers/adminSlice';

const Sidebar = () => {
	const dispatch = useDispatch();
	const [showSidebar, setShowSidebar] = useState(false);
	const { auth: {admin}, admin: {currentAgeGroup} } = useSelector(state => state);

	return (
		<div className="sidebar-container">
			<div className={`sidebar ${showSidebar ? 'show-sidebar' : 'hide-sidebar'}`}>
				<h2 className="sidebar-username">{admin.username}</h2>
				<div className="sidebar-links">
					{Array(12).fill(true).map((_, i) => {
						return (
							<div
								className={`sidebar-link ${i === currentAgeGroup ? 'selected-age-group' : ''}`}
								key={i}
								onClick={() => {
									if(i === currentAgeGroup)
										return dispatch(setAgeGroup({ageGroup: -1}));
									dispatch(setAgeGroup({ ageGroup: i }))
								}}
							>
								<div className="sidebar-link-container">
									{i} - {i + 1}
								</div>
							</div>
						);
					})}
					<div
						className={`sidebar-link ${currentAgeGroup === 13 ? 'selected-age-group' : ''}`}
						onClick={() => {
							if(currentAgeGroup === 13)
								return dispatch(setAgeGroup({ageGroup: -1}));
							dispatch(setAgeGroup({ ageGroup: 13 }))
						}}
					>
						<div className="sidebar-link-container">12+</div>
					</div>
				</div>
			</div>
			<div className={`sidebar-toggle ${showSidebar ? 'show-sidebar-toggle' : 'hide-sidebar-toggle'}`}>
				{
					showSidebar
					?
					<AiOutlineLeft onClick={() => setShowSidebar(false)}/>
					:
					<AiOutlineRight onClick={() => setShowSidebar(true)}/>
				}
			</div>
		</div>
	);
};

export default Sidebar;
