import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    books: [],
    currentAgeGroup: 0,
    bookLimit: 12,
    searchQuery: "",
    loading: false,
    categoryId: '',
    sectionId: '',
    categories: [],
    categoryLimit: 30,
    sections: [],
    dataType: [],
    currentBook: {
        id: '',
        isbn: '',
        name: '',
        min_age: '',
        max_age: '',
        image: null,
        categories: [],
        rating: '',
        review_count: '',
        isAdding: false,
        isEditing: false,
    }
};

export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setBooks: (state, action) => {
            state.books = action.payload.books;
            state.loading = false;
            state.refresh = false;
        },
        addBooks: (state, action) => {
            const booksMap = new Map();
			const newBooks = [ ...state.books, ...action.payload.books ];
			newBooks.forEach(book => {
				booksMap.set(book.isbn, book);
			});
			state.books = Array.from(booksMap.values());
            state.loading = false;
            state.refresh = false;
        },
        removeBook: (state, action) => {
            state.books = state.books.filter(
                (book) => book.isbn !== action.payload.isbn
            );
        },
        replaceBook: (state, action) => {
            state.books = state.books.map(book => {
                if(book.id === action.payload.book.id) 
                    return action.payload.book;
                return book;
            })
        },
        setCurrentBook: (state, action) => {
            state.currentBook = action.payload.currentBook;
        },
        initializeAddBook: (state) => {
            state.currentBook = {...initialState.currentBook, isAdding: true};
        },
        increaseBookLimit: (state) => {
            state.bookLimit = state.books.length + 12;
        },
        setCategories: (state, action) => {
            state.categories = action.payload.categories;
        },
        removeCategory: (state, action) => {
            state.categories = state.categories.filter(
                (category) => category.id !== action.payload.id
            );
        },
        replaceCategory: (state, action) => {
            state.categories = state.categories.map(category => {
                if(category.id === action.payload.category.id) 
                    return action.payload.category;
                return category;
            })
        },
        setSections: (state, action) => {
            state.sections = action.payload.sections;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload.searchQuery;
            state.bookLimit = initialState.bookLimit;
            state.selectedFilters = initialState.selectedFilters;
            state.currentAgeGroup = -1;
        },
        setDataType: (state, action) => {
            state.dataType = action.payload.dataType;
            state.bookLimit = initialState.bookLimit;
            state.categoryLimit = initialState.categoryLimit;
            state.ageGroup = -1;
        },
        setAgeGroup: (state, action) => {
            state.currentAgeGroup = action.payload.ageGroup;
            state.bookLimit = initialState.bookLimit;
        },
        setSectionId: (state, action) => {
            state.sectionId = action.payload.sectionId;
            state.bookLimit = initialState.bookLimit;
        },
        setCategoryId: (state, action) => {
            state.categoryId = action.payload.categoryId;
            state.bookLimit = initialState.bookLimit;
        },
        load: (state) => {
            state.loading = true;
        },
        stopLoad: (state) => {
            state.loading = false;
        },
    },
});

export const {
    setSectionId,
    replaceBook,
    replaceCategory,
    removeCategory,
    setDataType,
    initializeAddBook,
    setSections,
    setCurrentBook,
    setCategories,
    setCategoryId,
    stopLoad,
    removeBook,
    setAgeGroup,
    increaseBookLimit,
    addBooks,
    setBooks,
    load,
    setSearchQuery,
} = adminSlice.actions;

export default adminSlice.reducer;
