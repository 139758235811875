import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import {
  setSectionId,
  setDataType,
  removeBook,
  initializeAddBook,
  setCurrentBook,
  increaseBookLimit,
  setCategoryId,
  load,
  stopLoad,
  replaceBook,
} from "../../../reducers/adminSlice";
import { setAlert } from "../../../reducers/authSlice";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import axios from "axios";
import { bookColumns } from "./constants";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const OurLibrary = () => {
  const [showCategories, setShowCategories] = useState(false);
  const {
    api: { urls },
    admin: { sectionId, sections, categories, books, categoryId },
  } = useSelector((state) => state);
  const [deletingIndex, setDeletingIndex] = useState(-1);
  const dispatch = useDispatch();
  const loadMoreRef = useRef();
  const navigate = useNavigate();
  const [booksCsv, setBooksCsv] = useState(null);
  const [showBooksCsv, setShowBooksCsv] = useState(false);
  const [addedIsbns, setAddedIsbns] = useState([]);
  const [notAddedIsbns, setNotAddedIsbns] = useState([]);
  const [stockAvailable, setStockAvailable] = useState(0);
  const [rentals, setRentals] = useState(0);
  const [updatingIndex, setUpdatingIndex] = useState(-1);
  const [DownloadLink, setDownloadLink] = useState(null);
  const [creatingExcel,setCreatingExcel]=useState(false)

  const viewBook = (book) => {
    dispatch(setCurrentBook({ currentBook: book }));
    navigate(`/dashboard/books/${book.isbn}`);
  };

  const goToAddBook = () => {
    dispatch(initializeAddBook());
    navigate(`/dashboard/books/add`);
  };

  const initializeUpdateBook = (i) => {
    setUpdatingIndex(i);
    setStockAvailable(books[i].stock_available);
    setRentals(books[i].rentals);
  };

  const addBooksFromCsv = async () => {
    if (!booksCsv) return;
    dispatch(load());
    try {
      const formData = new FormData();
      formData.append("books_csv", booksCsv);
      const response = await axios.post(urls.addBooksFromCsv, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      if (
        !response.data.added_isbns.length &&
        !response.data.not_added_isbns.length
      )
        dispatch(
          setAlert({
            text: "No valid book details were found",
            color: "#F75549",
          })
        );
      else {
        setAddedIsbns(response.data.added_isbns);
        setNotAddedIsbns(response.data.not_added_isbns);
      }
      dispatch(stopLoad());
    } catch (err) {
      dispatch(stopLoad());
      dispatch(setAlert({ text: err.response.data.message, color: "#F75549" }));
      console.log(err);
    }
  };

  const updateBookQuantity = async () => {
    try {
      const response = await axios.post(
        urls.updateBookQuantity,
        {
          id: books[updatingIndex].id,
          stock_available: stockAvailable,
          rentals: rentals,
        },
        { withCredentials: true }
      );
      setUpdatingIndex(-1);
      dispatch(replaceBook({ book: response.data.book }));
    } catch (err) {
      dispatch(setAlert({ text: err.response.data.message, color: "#F75549" }));
      console.log(err);
    }
  };

  const deleteBook = async () => {
    try {
      const isbn = books[deletingIndex].isbn;
      await axios.post(
        urls.deleteBook,
        { id: books[deletingIndex].id },
        { withCredentials: true }
      );
      dispatch(removeBook({ isbn }));
      setDeletingIndex(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadExcel=async() =>{
   
    try {
      console.log(1)
      setCreatingExcel(true);
      const response= await axios.get(`https://server.brightr.club/api_v2_books/create_xlsx`)
      console.log(response.data.url)
      setDownloadLink(response.data.url)
      setCreatingExcel(false)
      
    } catch (error) {
      setCreatingExcel(false)
      console.error(error)
    }

  }

  const loadMore = () => {
    if (loadMoreRef.current) {
      if (window.innerHeight + window.scrollY >= loadMoreRef.current.offsetTop)
        dispatch(increaseBookLimit());
    }
  };



  useEffect(
    () => {
      dispatch(setDataType({ dataType: "books" }));
      window.addEventListener("scroll", loadMore);
      return () => {
        window.removeEventListener("scroll", loadMore);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  

  return (
    <div className="our-library">
      <div className="dashboard-title">
        <h2>
          <img src="/icons/our-library-dashboard.png" alt="Customers" />
          Books
        </h2>
        <button className="gold-button" onClick={goToAddBook}>
          Add Book
        </button>
      </div>
      <div className="add-books-csv">
        <input
          type="button"
          className="gold-button"
          value="Add Books from CSV"
          onClick={() => setShowBooksCsv(true)}
        />
        <button onClick={()=>handleDownloadExcel()} className="downloadCSV" >
          {creatingExcel? <>Creating Excel Sheet .....</>:<>Download Excel Sheet</>}
        </button>
        {
          DownloadLink && 
          <>
          <a style={{textDecoration:"underline"}} href={DownloadLink}>{DownloadLink}</a></>
        }
        <div
          className={`backdrop add-books ${
            showBooksCsv ? "show-backdrop" : ""
          }`}
        >
          {addedIsbns.length || notAddedIsbns.length ? (
            <div className="backdrop-container">
              <h3>Result</h3>
              <div className="column-names">
                <p>ISBNs Added</p>
                {addedIsbns.map((isbn) => (
                  <p key={isbn}>{isbn}</p>
                ))}
              </div>
              <div className="column-names">
                <p>ISBNs Not Added</p>
                {notAddedIsbns.map((isbn) => (
                  <p key={isbn}>{isbn}</p>
                ))}
              </div>
              <div>
                <button
                  className="gold-border-button"
                  onClick={() => {
                    setAddedIsbns([]);
                    setNotAddedIsbns([]);
                    setShowBooksCsv(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          ) : (
            <div className="backdrop-container">
              <h3>Add Books from CSV</h3>
              <input
                type="file"
                onChange={(e) => setBooksCsv(e.target.files[0])}
              />
              <div className="column-names">
                <p>Required Columns</p>
                {bookColumns.required.map((column) => (
                  <p key={column}>{column}</p>
                ))}
              </div>
              <div className="column-names">
                <p>Optional Columns</p>
                {bookColumns.optional.map((column) => (
                  <p key={column}>{column}</p>
                ))}
              </div>
              <div className="column-names">
                <p>Multiple Columns (Use as prefix)</p>
                {bookColumns.multiple.map((column) => (
                  <p key={column}>{column}</p>
                ))}
              </div>
              <div>
                <button className="gold-button" onClick={addBooksFromCsv}>
                  Add
                </button>
                <button
                  className="gold-border-button"
                  onClick={() => setShowBooksCsv(false)}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="book-sections">
        <div
          className={`book-section ${
            !sectionId ? "selected-book-section" : ""
          }`}
          onClick={() => dispatch(setSectionId({ sectionId: "" }))}
        >
          All
        </div>
        {sections.map((section) => {
          return (
            <div
              key={section.id}
              className={`book-section ${
                sectionId === section.id ? "selected-book-section" : ""
              }`}
              onClick={() => dispatch(setSectionId({ sectionId: section.id }))}
            >
              {section.name}
            </div>
          );
        })}
      </div>
      <div className="book-categories">
        <div className="category-button-container">
          <div className="category-button">
            <p>Category</p>
            {showCategories ? (
              <AiOutlineUp onClick={() => setShowCategories(false)} />
            ) : (
              <AiOutlineDown onClick={() => setShowCategories(true)} />
            )}
          </div>
        </div>
        <div
          className={`category-dropdown ${
            showCategories ? "show-category-dropdown" : ""
          }`}
        >
          <div className="category-dropdown-list">
            <div className="book-category">
              <b>Category</b>
              <div className="book-category-list">
                <div
                  className="category"
                  onClick={() => dispatch(setCategoryId({ categoryId: "" }))}
                >
                  <span
                    style={!categoryId ? { backgroundColor: "#DBDBDB" } : {}}
                  >
                    All
                  </span>
                </div>
                {categories.map((category) => {
                  return (
                    <div className="category" key={category.id}>
                      <span
                        onClick={() =>
                          dispatch(setCategoryId({ categoryId: category.id }))
                        }
                        style={
                          category.id === categoryId
                            ? { backgroundColor: "#DBDBDB" }
                            : {}
                        }
                      >
                        {category.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="books">
        {books.map((book, i) => {
          return (
            <div className="book" key={i}>
              <div className="book-image">
                <img src={book.image} alt="Book" />
              </div>
              <p>{book.name.split(":")[0]}</p>
              <div className="book-details">
                {book.rating && (
                  <div className="book-detail">
                    <p>{book.rating}</p>
                    <img src="/icons/star.png" alt="Rating" />
                  </div>
                )}
                {book.review_count && !isNaN(book.review_count) && (
                  <div className="book-detail">
                    <img src="/icons/reviews.png" alt="Reviews" />
                    <p>{Number(book.review_count).toLocaleString()}</p>
                  </div>
                )}
              </div>
              <div className="book-overlay">
                <div className="book-overlay-row">
                  <input
                    type="button"
                    className="gold-button"
                    value="View"
                    onClick={() => viewBook(book)}
                  />
                  <input
                    type="button"
                    className="gold-button"
                    style={{ backgroundColor: "red" }}
                    value="Remove"
                    onClick={() => setDeletingIndex(i)}
                  />
                </div>
                <div className="book-overlay-row">
                  <p>
                    Available <span>{book.stock_available || 0}</span>
                  </p>
                  <p>
                    Rented <span>{book.rentals || 0}</span>
                  </p>
                </div>
                <p>
                  Wishlisted <span>{book.wishlist_count || 0}</span>
                </p>
                <p>
                  Previously Read <span>{book.previous_count || 0}</span>
                </p>
                <input
                  type="button"
                  className="gold-button"
                  value="Update Quantity"
                  onClick={() => initializeUpdateBook(i)}
                  style={{
                    backgroundColor: "lightblue",
                    width: "150px",
                    color: "black",
                  }}
                />
              </div>
              <div
                className={`backdrop ${
                  i === deletingIndex ? "show-backdrop" : ""
                }`}
              >
                <div className="backdrop-container">
                  <h3>Are you sure you want to delete this book?</h3>
                  <div className="backdrop-buttons">
                    <input
                      className="gold-button"
                      type="button"
                      value="Cancel"
                      onClick={() => setDeletingIndex(-1)}
                      style={{ backgroundColor: "#4285F4" }}
                    />
                    <input
                      className="gold-button"
                      type="button"
                      value="Delete"
                      onClick={deleteBook}
                      style={{ backgroundColor: "red" }}
                    />
                  </div>
                </div>
              </div>
              {!Boolean(book.stock_available) && (
                <div className="book-not-available">
                  {book.return_date ? (
                    <p>
                      Returning on{" "}
                      <span>
                        <Moment format="D MMMM YYYY" withTitle>
                          {book.return_date}
                        </Moment>
                      </span>
                    </p>
                  ) : (
                    <p>Not Available</p>
                  )}
                </div>
              )}
            </div>
          );
        })}
        {books.length === 0 && (
          <p style={{ marginTop: "1.5rem" }}>No books to show</p>
        )}
      </div>
      {updatingIndex !== -1 && (
        <div className="update-book-quantity-container">
          <form className="update-book-quantity">
            <h3>Update Book Quantity</h3>
            <p>{books[updatingIndex].name}</p>
            <div className="input-field">
              <label>Stock Available</label>
              <input
                type="number"
                value={stockAvailable}
                onChange={(e) => setStockAvailable(e.target.value)}
              />
            </div>
            <div className="input-field">
              <label>Rentals</label>
              <input
                type="number"
                value={rentals}
                onChange={(e) => setRentals(e.target.value)}
              />
            </div>
            <input type="button" value="Update" onClick={updateBookQuantity} />
            <input
              type="button"
              value="Cancel"
              style={{ backgroundColor: "red" }}
              onClick={() => setUpdatingIndex(-1)}
            />
          </form>
        </div>
      )}
      <div ref={loadMoreRef} />
    </div>
  );
};

export default OurLibrary;
