import {useEffect} from 'react'; 
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Content from './components/Content';
import Dashboard from './components/Dashboard';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {login, clearAlert} from './reducers/authSlice';

const App = () => {
	const {api: {urls}, auth: {isLoggedIn, alert}, admin: {loading}} = useSelector(state => state);
	const location = useLocation();
	const dispatch = useDispatch();

	const refresh = async () => {
		try {
			const response = await axios.get(urls.refresh, {withCredentials: true});
			dispatch(login({admin: response.data.admin}));
		} catch {}
	};

	useEffect(() => {
		refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() => {
			window.scrollTo(0, 0);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ location ]
	);

	useEffect(() => {
		setTimeout(() => {
			dispatch(clearAlert());
		}, 5000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alert]);

	return (
		<div className="app">
			{alert.text && <p className="alert" style={{ backgroundColor: alert.color }}>{alert.text}</p>}
			{loading && 
				<div className="loading-spinner-container">
					<div className='loading-spinner'></div>
				</div>
			}
			<Header />
			<Routes>
				<Route path="/login" element={isLoggedIn ? <Navigate to='/'/> : <Login />} />
				<Route path="/about-us" element={<Content type='About Us'/>} />
				<Route path="/contact-us" element={<Content type='Contact Us'/>} />
				<Route path="/disclaimer" element={<Content type='Disclaimer'/>} />
				<Route path="/privacy-policy" element={<Content type='Privacy Policy'/>} />
				<Route path="/refund-policy" element={<Content type='Refund Policy'/>} />
				<Route path="/terms-and-conditions" element={<Content type='Terms and Conditions'/>} />
				<Route path="/dashboard/*" element={isLoggedIn ? <Dashboard /> : <Navigate to='/login'/>} />
				<Route path="/" element={isLoggedIn ? <Navigate to='/dashboard'/> : <Navigate to='/login'/>} />
			</Routes>
			<Footer />
		</div>
	);
};

export default App;
