import { useEffect } from 'react';
import { load, addBooks, setBooks, stopLoad, setCategories, setSections } from '../../reducers/adminSlice';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Book from './OurLibrary/Book';
import OurLibrary from './OurLibrary';
import Sidebar from './Sidebar';
import Categories from './Categories';
import './styles.scss';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Genres from './Genres';

const Dashboard = () => {
	const dispatch = useDispatch();
	const {
		admin: {
			currentAgeGroup,
			bookLimit,
			categoryId,
			searchQuery,
			loading,
			sectionId,
		},
		api: { urls },
	} = useSelector(state => state);
	const location = useLocation();

	const getBooks = async () => {
		if (loading) return;
		try {
			dispatch(load());
			const response = await axios.get(urls.getBooks, {
				params: {
					start: bookLimit - 12,
					end: bookLimit,
					search_query: searchQuery,
					age: currentAgeGroup,
					category_id: categoryId,
					section_id: sectionId,
				},
			});
			if (bookLimit === 12) dispatch(setBooks({ books: response.data.books }));
			else dispatch(addBooks({ books: response.data.books }));
		} catch (err) {
			console.log(err);
		}
		dispatch(stopLoad());
	};
	
	const getCategories = async () => {
		if (loading) return;
		try {
			dispatch(load());
			const response = await axios.get(urls.getCategories, {
				params: {
					start: 0,
					end: 1000,
					search_query: searchQuery,
					age: currentAgeGroup,
				},
			});
			dispatch(setCategories({categories: response.data.categories}));
		} catch (err) {
			console.log(err);
		}
		dispatch(stopLoad());
	};

	const getSections = async () => {
		try {
			dispatch(load());
			const response = await axios.get(urls.getSections);
			dispatch(setSections({sections: response.data.sections}));
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(
		() => {
			getBooks();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ 
			categoryId,
			searchQuery, 
			bookLimit, 
			currentAgeGroup,
			sectionId,
		]
	);

	useEffect(() => {
		getCategories();
	}, [currentAgeGroup, searchQuery]);

	useEffect(() => {
		getSections();
	}, []);
	
	return (
		<div className="dashboard">
			{['/dashboard/books', '/dashboard/categories','/dashboard/genres'].includes(location.pathname) && <Sidebar />}
			<div className="dashboard-content">
				<Routes>
					<Route path='/categories' element={<Categories/>}/>
					<Route path='/genres' element={<Genres/>}/>
					<Route path='/books/:isbn' element={<Book/>}/>
					<Route path='/books' element={<OurLibrary/>}/>
					<Route path='/' element={<Navigate to='/dashboard/books'/>}/>
				</Routes>
			</div>
		</div>
	);
};

export default Dashboard;
