import { useState, useEffect, Fragment } from "react";
import { replaceCategory, removeCategory, setDataType } from "../../../reducers/adminSlice";
import { setAlert } from "../../../reducers/authSlice";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux";
import {FaEdit, FaTrash, FaCheck} from 'react-icons/fa';
import axios from 'axios';

const Categories = () => {
    const {api: {urls}, admin: {categories}} = useSelector(state => state);
    const dispatch = useDispatch();
    const [deletingIndex, setDeletingIndex] = useState(-1);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [isAddingCategory, setIsAddingCategory] = useState(false);
    const [name, setName] = useState('');
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');

    const addCategory = async () => {
        try {
            await axios.post(urls.addCategory, {
                name,
                min_age: minAge,
                max_age: maxAge,
            }, {withCredentials: true});
            dispatch(setAlert({text: 'Category added', color: '#33A200'}));
            setName('');
            setMinAge('');
            setMaxAge('');
            setIsAddingCategory(false);
        } catch (err) {
            dispatch(setAlert({text: err.response.data.message, color: 'red'}))
        }
    };

    const deleteCategory = async () => {
		try {
			const id = categories[deletingIndex].id;
            await axios.post(urls.deleteCategory, {id: categories[deletingIndex].id}, {withCredentials: true});
			dispatch(removeCategory({id}));
            dispatch(setAlert({text: 'Category deleted', color: '#33A200'}));
			setDeletingIndex(-1);
        } catch (err) {
            dispatch(setAlert({text: err.response.data.message, color: 'red'}))
        }
	};

    const updateCategory = async () => {
        try {
			const id = categories[editingIndex].id;
            const response = await axios.post(urls.updateCategory, {
                id,
                name,
                min_age: minAge,
                max_age: maxAge,
            }, {withCredentials: true});
			dispatch(replaceCategory({category: response.data.category}));
            dispatch(setAlert({text: 'Category updated', color: '#33A200'}));
            setName('');
            setMinAge('');
            setMaxAge('');
            setEditingIndex(-1);
        } catch (err) {
            dispatch(setAlert({text: err.response.data.message, color: 'red'}))
        }
    };

    const initializeEditing = async (i) => {
        setEditingIndex(i);
        setDeletingIndex(-1);
        setIsAddingCategory(false);
        setName(categories[i].name);
        setMinAge(categories[i].min_age);
        setMaxAge(categories[i].max_age);
    };

    useEffect(() => {
        dispatch(setDataType({dataType: 'categories'}));
    }, []);

    useEffect(() => {
        if(isAddingCategory) {
            setName('');
            setMinAge('');
            setMaxAge('');
            setEditingIndex(-1);
        }
    }, [isAddingCategory]);

    return (
        <div className='categories-container'>
            <div className="dashboard-title">
				<h2>
					<img src="/icons/our-library-dashboard.png" alt="Customers" />
					Categories
				</h2>
				<button 
                    className='gold-button' 
                    onClick={() => setIsAddingCategory(_ => !_)}
                    style={isAddingCategory ? {backgroundColor: 'red'} : {}}
                >
                    {isAddingCategory ? 'Cancel' : 'Add Category'}
                </button>
			</div>
            {isAddingCategory && 
                <div className='add-category'>
                    <h3>Add Category</h3>
                    <div className='add-category-form'>
                        <div className='add-category-input'>
                            <span>Name</span>
                            <input type='text' value={name} onChange={(e) => setName(e.target.value)}/>
                        </div>
                        <div className='add-category-input'>
                            <span>Minimum Age</span>
                            <input type='number' value={minAge} onChange={(e) => setMinAge(e.target.value)}/>
                        </div>
                        <div className='add-category-input'>
                            <span>Maximum Age</span>
                            <input type='number' value={maxAge} onChange={(e) => setMaxAge(e.target.value)}/>
                        </div>
                    </div>
                    <button className='gold-button add-category-button' onClick={addCategory}>
                        Add
                    </button>
                </div>
            }
            <div className='categories'>
                {categories.map((category, i) => {
                    return (
                        <div className='category'>
                            {i === editingIndex 
                                ?
                                <textarea value={name} onChange={(e) => setName(e.target.value)}/>
                                :
                                <h4>{category.name}</h4>
                            }
                            <div className='category-ages'>
                                <div className='category-age'>
                                    <span>Minimum Age</span>
                                    {i === editingIndex 
                                        ?
                                        <input type='number' value={minAge} onChange={(e) => setMinAge(e.target.value)}/>
                                        :
                                        <p>{category.min_age}</p>
                                    }
                                </div>
                                <div className='category-age'>
                                    <span>Maximum Age</span>
                                    {i === editingIndex 
                                        ?
                                        <input type='number' value={maxAge} onChange={(e) => setMaxAge(e.target.value)}/>
                                        :
                                        <p>{category.max_age}</p>
                                    }
                                </div>
                            </div>
                            {i !== editingIndex &&
                                <Fragment>
                                    <button className='category-button category-edit-button' onClick={() => initializeEditing(i)}>
                                        <FaEdit/>
                                    </button>
                                    <button className='category-button category-delete-button' onClick={() => setDeletingIndex(i)}>
                                        <FaTrash/>
                                    </button>
                                </Fragment>
                            }
                            {i === editingIndex &&
                                <button className='category-button category-delete-button'onClick={updateCategory}>
                                    <FaCheck style={{color: '#33A200'}} />
                                </button>
                            }
                            <div className={`backdrop ${i === deletingIndex ? 'show-backdrop' : ''}`}>
								<div className="backdrop-container">
									<h3>Are you sure you want to delete this category?</h3>
									<div className="backdrop-buttons">
										<input
											className='gold-button'
											type="button"
											value="Cancel"
											onClick={() => setDeletingIndex(-1)}
											style={{ backgroundColor: '#4285F4' }}
										/>
										<input
											className='gold-button'
											type="button"
											value="Delete"
											onClick={deleteCategory}
											style={{ backgroundColor: 'red' }}
										/>
									</div>
								</div>
							</div>
                        </div>
                    );
                })}
                {categories.length === 0 &&
                    <p style={{marginTop: '1.5rem'}}>No categories to show</p>}
            </div>
        </div>
    );
};

export default Categories;