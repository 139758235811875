import { useState, useEffect, Fragment } from "react";
import { replaceCategory, removeCategory, setDataType } from "../../../reducers/adminSlice";
import { setAlert } from "../../../reducers/authSlice";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux";
import {FaEdit, FaTrash, FaCheck} from 'react-icons/fa';
import axios from 'axios';

const Genres = () => {
    const {api: {urls}, admin: {currentAgeGroup,categories}} = useSelector(state => state);
    const dispatch = useDispatch();
    const [deletingIndex, setDeletingIndex] = useState(-1);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [isAddingCategory, setIsAddingCategory] = useState(false);
    const [name, setName] = useState('');
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');
    const [genre,setGenre]=useState(null);

    const addCategory = async () => {
        try {
            await axios.post(`https://server.brightr.club/api_v2_books/create-genre`, {
                genre_name:name,
                min_age: minAge,
                max_age: maxAge,
            }, {withCredentials: true});
            fetchGenres();
            dispatch(setAlert({text: 'Category added', color: '#33A200'}));
            setName('');
            setMinAge('');
            setMaxAge('');
            setIsAddingCategory(false);
        } catch (err) {
            dispatch(setAlert({text: err.response.data.message, color: 'red'}))
        }
    };

    const deleteCategory = async () => {
		try {
			const id = genre[deletingIndex].id;
            console.log(id);
            const genre_name=genre[deletingIndex].name;
            console.log(genre_name)
            const response= await axios.get(`https://server.brightr.club/api_v2_books/delete-genre/${genre_name}`, {withCredentials: true});
            
            dispatch(setAlert({text: 'Category deleted', color: '#33A200'}));
            fetchGenres();
			setDeletingIndex(-1);
        } catch (err) {
    
            dispatch(setAlert({text: err.response.data.message, color: 'red'}))

        }
	};

    const updateCategory = async () => {
        try {
			const id = genre[editingIndex].id;
            const genre_name=genre[editingIndex].name;
            console.log(id)
            console.log(genre_name)
            const response = await axios.post(`https://server.brightr.club/api_v2_books/update-genre/${genre_name}`, {
                name:name,
                min_age: minAge,
                max_age: maxAge,
            }, {withCredentials: true});
		   	fetchGenres();
            dispatch(setAlert({text: 'Genre updated', color: '#33A200'}));
            setName('');
            setMinAge('');
            setMaxAge('');
            setEditingIndex(-1);
        } catch (err) {
            dispatch(setAlert({text: err.response.data.message, color: 'red'}))
        }
    };

    const initializeEditing = async (i) => {
        setEditingIndex(i);
        setDeletingIndex(-1);
        setIsAddingCategory(false);
        setName(genre[i].name);
        setMinAge(genre[i].min_age);
        setMaxAge(genre[i].max_age);
    };

    const fetchGenres = async (i) => {
        try {
            const response = await axios.get(`https://server.brightr.club/api_v2_books/get-genres?age=${currentAgeGroup}`);
            // Extracting unique genres based on genre name from the response
            const uniqueGenres = response.data.genres.reduce((unique, genre) => {
                // Check if the genre name is already present
                const found = unique.find(item => item.name === genre.name);
                // If not found, add it to the unique list
                if (!found) unique.push(genre);
                return unique;
            }, []);
            setGenre(uniqueGenres);
        } catch (error) {
            console.error(error)
        }
        
    };

    useEffect(() => {
    fetchGenres();
    }, [currentAgeGroup]);

    useEffect(() => {
        if(isAddingCategory) {
            setName('');
            setMinAge('');
            setMaxAge('');
            setEditingIndex(-1);
        }
    }, [isAddingCategory]);

    return (
        <div className='categories-container'>
            {<div className="dashboard-title">
				<h2>
					<img src="/icons/our-library-dashboard.png" alt="Customers" />
					Genres
				</h2>
				<button 
                    className='gold-button' 
                    onClick={() => setIsAddingCategory(_ => !_)}
                    style={isAddingCategory ? {backgroundColor: 'red'} : {}}
                >
                    {isAddingCategory ? 'Cancel' : 'Add Category'}
                </button>
    </div>}
            { isAddingCategory && 
                <div className='add-category'>
                    <h3>Add Category</h3>
                    <div className='add-category-form'>
                        <div className='add-category-input'>
                            <span>Name</span>
                            <input type='text' value={name} onChange={(e) => setName(e.target.value)}/>
                        </div>
                        <div className='add-category-input'>
                            <span>Minimum Age</span>
                            <input type='number' value={minAge} onChange={(e) => setMinAge(e.target.value)}/>
                        </div>
                        <div className='add-category-input'>
                            <span>Maximum Age</span>
                            <input type='number' value={maxAge} onChange={(e) => setMaxAge(e.target.value)}/>
                        </div>
                    </div>
                    <button className='gold-button add-category-button' onClick={addCategory}>
                        Add
                    </button>
                </div>
            }
            <div className='categories'>
                {genre && genre.map((genre, i) => {
                    return (
                        <div className='category'>
                            {i === editingIndex 
                                ?
                                <textarea value={name} onChange={(e) => setName(e.target.value)}/>
                                :
                                <h4>{genre.name}</h4>
                            }
                            <div className='category-ages'>
                                <div className='category-age'>
                                    <span>Minimum Age</span>
                                    {i === editingIndex 
                                        ?
                                        <input type='number' value={minAge} onChange={(e) => setMinAge(e.target.value)}/>
                                        :
                                        <p>{genre.min_age}</p>
                                    }
                                </div>
                                <div className='category-age'>
                                    <span>Maximum Age</span>
                                    {i === editingIndex 
                                        ?
                                        <input type='number' value={maxAge} onChange={(e) => setMaxAge(e.target.value)}/>
                                        :
                                        <p>{genre.max_age}</p>
                                    }
                                </div>
                            </div>
                            {i !== editingIndex &&
                                <Fragment>
                                    <button className='category-button category-edit-button' onClick={() => initializeEditing(i)}>
                                        <FaEdit/>
                                    </button>
                                    <button className='category-button category-delete-button' onClick={() => setDeletingIndex(i)}>
                                        <FaTrash/>
                                    </button>
                                </Fragment>
                            }
                            {i === editingIndex &&
                                <button className='category-button category-delete-button'onClick={updateCategory}>
                                    <FaCheck style={{color: '#33A200'}} />
                                </button>
                            }
                            <div className={`backdrop ${i === deletingIndex ? 'show-backdrop' : ''}`}>
								<div className="backdrop-container">
									<h3>Are you sure you want to delete this category?</h3>
									<div className="backdrop-buttons">
										<input
											className='gold-button'
											type="button"
											value="Cancel"
											onClick={() => setDeletingIndex(-1)}
											style={{ backgroundColor: '#4285F4' }}
										/>
										<input
											className='gold-button'
											type="button"
											value="Delete"
											onClick={deleteCategory}
											style={{ backgroundColor: 'red' }}
										/>
									</div>
								</div>
							</div>
                        </div>
                    );
                })}
                {genre && genre.length === 0 &&
                    <p style={{marginTop: '1.5rem'}}>No Genres to show</p>}
            </div>
        </div>
    );
};

export default Genres;