import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {setAlert, login } from '../../reducers/authSlice';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

const Login = () => {
	const {urls} = useSelector(state => state.api);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [ showPassword, setShowPassword ] = useState(false);

	const handleSubmit = async event => {
		event.preventDefault();
		try {
			const response = await axios.post(urls.login, { username, password }, { withCredentials: true });
			dispatch(login({ admin: response.data.admin }));
			navigate('/');
		} catch (err) {
			dispatch(setAlert({ text: err.response.data.message, color: '#F75549' }));
			console.log(err);
		}
	};

	return (
		<div className="form-container">
			<form className="form">
				<h2>Login</h2>
				<div className="input-field">
					<label>Username</label>
					<input type="text" value={username} onChange={({target: {value}}) => setUsername(value)} />
				</div>
				<div className="input-field password-field">
					<label>Password</label>
					<input
						type={showPassword ? 'text' : 'password'}
						value={password}
						onChange={({ target: { value } }) => setPassword(value.trim())}
					/>
					{showPassword
						? <AiFillEye onClick={() => setShowPassword(false)} />
						: <AiFillEyeInvisible onClick={() => setShowPassword(true)} />}
				</div>
				<button className="gold-button" onClick={handleSubmit}>Login</button>
			</form>
		</div>
	);
};

export default Login;
