import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './reducers/adminSlice';
import authReducer from './reducers/authSlice';
import apiReducer from './reducers/apiSlice';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		admin: adminReducer,
		api: apiReducer,
	},
});
