import { Fragment, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setSearchQuery } from '../../reducers/adminSlice';
import {GrClose} from 'react-icons/gr';
import {AiOutlineSearch} from 'react-icons/ai';

const Header = () => {
	const [query, setQuery] = useState('');
	const dispatch = useDispatch();
	const [ showDropDown, setShowDropDown ] = useState(false);
	const { auth: { isLoggedIn }, api: { urls }, admin: {dataType, searchQuery} } = useSelector(state => state);

	const search = async () => {
		dispatch(setSearchQuery({searchQuery: query}));
	};

	const logout = async () => {
		try {
			await axios.post(urls.logout, {}, { withCredentials: true });
			window.location.reload();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<header>
			<div className="top-header">
				<Link to='/'>
					<h1>Bright<span>R</span>.Club</h1>
				</Link>
				{isLoggedIn &&
					<div className="search-bar">
						<input
							type="text"
							placeholder={dataType === 'user' ? "Search by name or mobile number..." : "Enter a query..."}
							value={query}
							onChange={({ target: { value } }) => setQuery(value)}
						/>
						{searchQuery && query && <button className='reset-search' onClick={() => {
							setQuery('');
							dispatch(setSearchQuery({searchQuery: ''}))
						}}>
							<GrClose color='#FF8513'/>
						</button>}
						<button onClick={search}>
							<AiOutlineSearch/>
						</button>
					</div>}
				<div className={`header-actions ${showDropDown ? 'show-drop-down' : 'hide-drop-down'}`}>
					{isLoggedIn && 
						<Fragment>
							<Link to='/dashboard/books'>Books</Link>
							<Link to='/dashboard/categories'>Categories</Link>
							<Link to='/dashboard/genres'>Genres</Link>
							<button onClick={logout} className="subscribe-button">Log Out</button>
						</Fragment>
					}
				</div>
				<GiHamburgerMenu onClick={() => setShowDropDown(!showDropDown)} />
			</div>
			{isLoggedIn &&
				<div className="search-bar mobile-search-bar">
					<input
						type="text"
						placeholder="Enter query..."
						value={query}
						onChange={({ target: { value } }) => setQuery(value)}
					/>
					<button onClick={search}>
						<AiOutlineSearch/>
					</button>
				</div>}
		</header>
	);
};

export default Header;
