import { createSlice } from '@reduxjs/toolkit';

const adminDomain = (process.env.REACT_APP_API || 'http://localhost:5000') + '/api_admin';
const newBooksDomain = (process.env.REACT_APP_API || 'http://localhost:5000') + '/api_v2_books';

const initialState = {
	urls: {
		login: adminDomain + '/login',
		logout: adminDomain + '/logout',
		refresh: adminDomain + '/refresh',
		getBooks: newBooksDomain + '/get-new-books',
		getCategories: newBooksDomain + '/get-categories',
		addOrUpdateBook: newBooksDomain + '/new-book',
		getSections: newBooksDomain + '/get-sections',
		deleteBook: newBooksDomain + '/delete-new-book',
		addCategory: newBooksDomain + '/add-category',
		deleteCategory: newBooksDomain + '/delete-category',
		updateCategory: newBooksDomain + '/update-category',
		addBooksFromCsv: newBooksDomain + '/add-books-from-csv',
		updateBookQuantity: newBooksDomain + '/update-book-quantity',
	},
};

export const apiSlice = createSlice({
	name: 'api',
	initialState,
});

export default apiSlice.reducer;
